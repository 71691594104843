import * as React from 'react'
import Highlight from 'react-highlight'
import { SEO } from '../../components/seo'

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <header className="container container--grid">
          <div className="container__row" style={{ margin: '48px 0' }}>
            <h1 className="type-h1">WYSIWYG Elements</h1>
          </div>
        </header>
        <div className="container container--grid">
          <div className="container__row">
            <Section heading="Headings">
              <h1>Heading level 1</h1>
              <h2>Heading level 2</h2>
              <h3>Heading level 3</h3>
              <h4>Heading level 4</h4>
              <h5>Heading level 5</h5>
              <h6>Heading level 6</h6>
            </Section>
            <Section heading="Button / CTA">
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <a className="button" href="#">
                  Default
                </a>
              </div>
              <Highlight>
                {`<a class="button" href="https://google.com">Default</a>`}
              </Highlight>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <a className="button button--secondary" href="#">
                  Secondary
                </a>
              </div>
              <Highlight className="html">
                {`<a class="button button--secondary" href="/">Secondary</a>`}
              </Highlight>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <a className="button" href="#" target="_blank">
                  Open in New Tab
                </a>
              </div>
              <Highlight className="html">
                {`<a class="button" href="/dfg" target="_blank">Open in New Tab</a>`}
              </Highlight>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <a className="button button--width" href="#">
                  With Width
                </a>
              </div>
              <Highlight className="html">
                {`<a class="button button--width" href="#">With Width</a>`}
              </Highlight>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <a className="button button--icon" href="#">
                  With Arrow
                </a>
              </div>
              <Highlight className="html">
                {`<a class="button button--icon" href="#">With Arrow</a>`}
              </Highlight>
              <div style={{ marginTop: 24, marginBottom: 24 }}>
                <a
                  className="button button--width button--icon button--secondary"
                  href="#"
                >
                  Kitchen Sink
                </a>
              </div>
              <Highlight className="html">
                {`<a
  class="button button--width button--icon button--secondary"
  href="#"
>
  Kitchen Sink
</a>`}
              </Highlight>
            </Section>
            <Section heading="Dividing line">
              <hr />
            </Section>
            <Section heading="Paragraphs">
              <Section.Subheading>Lede</Section.Subheading>
              <p className="lede">
                Over the past 8 years we’ve invested in over 30 startups and
                have partnered with dozens of top design teams, from early stage
                to public companies.
              </p>
              <Section.Subheading>Default</Section.Subheading>
              <p>
                Over the past 8 years we’ve invested in over 30 startups and
                have partnered with dozens of top design teams, from early stage
                to public companies.
              </p>
              <p>
                Keep an eye out for signs that trust is fading as your team
                grows. “When people aren’t having candid, early-and-often type
                of conversations, and not showing napkin sketches or wireframes,
                you know something has gone awry, said Bradley Artziniega, a
                design lead at Medium. The purpose of design critiques are to
                solicit feedback on work at all stages of development. When
                presenting nearly finished or even finished work becomes the
                norm at every design critique, it’s a sign that designers no
                longer feel they can bring early-stage ideas in front of the
                group in a productive or safe way.
              </p>
              <p>
                Another indicator is unequal participation or lack of productive
                discussion among meeting attendees. Missed meetings or dread can
                be a sign that critiques have stopped being helpful or that
                they’ve even become a point of anxiety for team members. When we
                repeatedly cancel our standing design critique meeting, we can
                surmise that it’s no longer valuable to the team. This is when
                we shake up the process, said Amanda.
              </p>
              <p>
                Regardless of role or size of team, we hope these insights will
                inspire thoughtful discussion about how to adapt your design
                critique to meet your team’s needs and how to maintain a culture
                of trust.
              </p>
            </Section>
            <Section heading="Lists">
              <Section.Subheading>Ordered</Section.Subheading>
              <ol>
                {getListItems().map(l => (
                  <li key={l}>{l}</li>
                ))}
              </ol>
              <Section.Subheading>Unordered</Section.Subheading>
              <ul>
                {getListItems().map(l => (
                  <li key={l}>{l}</li>
                ))}
              </ul>
            </Section>
            <Section heading="Quotes">
              <Section.Subheading>Markdown</Section.Subheading>
              <blockquote>
                <p>
                  Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                  venenatis vestibulum. Vivamus sagittis lacus vel augue laoreet
                  rutrum faucibus dolor auctor. Maecenas sed diam eget risus
                  varius blandit sit amet non magna.
                </p>
              </blockquote>
              <Highlight className="md">{getExampleMarkdownQuote()}</Highlight>
              <Section.Subheading>Small (default)</Section.Subheading>
              <blockquote>
                <q>
                  Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                  venenatis vestibulum. Vivamus sagittis lacus vel augue laoreet
                  rutrum faucibus dolor auctor. Maecenas sed diam eget risus
                  varius blandit sit amet non magna.
                </q>
                <cite>Dan Morgan</cite>
              </blockquote>
              <Highlight>{getExampleBasicQuote()}</Highlight>
              <Section.Subheading>Medium</Section.Subheading>
              <blockquote className="medium">
                <q>
                  Another indicator is unequal participation or lack of
                  productive discussion among meeting attendees.
                </q>
                <cite>Devin Jacoviello</cite>
              </blockquote>
              <Highlight>{getExampleMediumQuote()}</Highlight>
              <Section.Subheading>Large</Section.Subheading>
              <blockquote className="large">
                <q>
                  Another indicator is unequal participation or lack of
                  productive discussion among meeting attendees.
                </q>
                <cite>Adam Ho</cite>
              </blockquote>
              <Highlight>{getExampleLargeQuote()}</Highlight>
              <Section.Subheading>w Avatar (small only)</Section.Subheading>
              <p>
                For small quotes with an attributed author photo, use the
                example immediately below. For <code>medium</code> and{' '}
                <code>large</code> quotes with images, use the examples under
                the "w Quote & Small Image" heading.
              </p>
              <blockquote className="with-avatar">
                <img src="https://picsum.photos/420/420" alt="Person" />
                <q>
                  This is a quote with an image of the author. It's a small
                  quote and it was written by Copilot.
                </q>
                <cite>Dan M</cite>
              </blockquote>
              <Highlight>{getExampleQuoteWithAvatar()}</Highlight>
              <Section.Subheading>
                <s>w Image (small only)</s> deprecated
              </Section.Subheading>
              <blockquote className="with-image">
                <img src="https://picsum.photos/420/420" alt="Person" />
                <div>
                  <q>
                    Another indicator is unequal participation or lack of
                    productive discussion among meeting attendees.
                  </q>
                  <cite>Adam Ho</cite>
                </div>
              </blockquote>
              <Highlight>{getExampleQuoteWithImage()}</Highlight>
            </Section>
            <Section heading="Images">
              <Section.Subheading>Small / inline</Section.Subheading>
              <img src="https://picsum.photos/640/420" alt="Computer" />
              <Highlight>{getExampleMarkdownImage()}</Highlight>
              <Section.Subheading>Wide</Section.Subheading>
              <figure className="wide">
                <img src="https://picsum.photos/640/420" alt="Office" />
              </figure>
              <Highlight>{getExampleWideImage()}</Highlight>
              <Section.Subheading>Full</Section.Subheading>
              <figure className="full">
                <img src="https://picsum.photos/640/420" alt="Meeting" />
              </figure>
              <Highlight>{getExampleFullImage()}</Highlight>
              <Section.Subheading>w Quote</Section.Subheading>
              <figure className="full with-quote">
                <img src="https://picsum.photos/420/420" alt="Meeting" />
                <blockquote className="medium">
                  <q>
                    We believe in designed design. Some people do not like
                    design.
                  </q>
                  <cite>Ben Blumenfeld</cite>
                </blockquote>
              </figure>
              <p>
                The image with quote block is essentially a <code>wide</code> or{' '}
                <code>full</code> image block with a <code>medium</code> quote
                immediately after the image
              </p>
              <Highlight>{getExampleImageWithQuote()}</Highlight>
              <Section.Subheading>w Quote & Small Image</Section.Subheading>
              <figure className="wide with-quote with-small-image">
                <img src="https://picsum.photos/420/420" alt="Meeting" />
                <blockquote className="medium">
                  <q>
                    We believe in designed design. Some people do not like
                    design.
                  </q>
                  <cite>Ben Blumenfeld</cite>
                </blockquote>
              </figure>
              <p>
                The <code>.with-small-image</code> modifier flips the balance of
                the image and quote columns. This works best with{' '}
                <code>medium</code> and <code>large</code> blockquotes. For{' '}
                <code>small</code> quotes with an image, see the "w Image (small
                only)" example in the quotes section.
              </p>
              <Highlight>{getExampleImageWithQuoteAndSmallImage()}</Highlight>
              <Section.Subheading>Wide with Caption</Section.Subheading>
              <figure className="wide">
                <img src="https://picsum.photos/640/420" alt="Office" />
                <figcaption>
                  A random Unsplash <em>office</em> image
                </figcaption>
              </figure>
              <Highlight>{getExampleWideImageCaption()}</Highlight>
              <Section.Subheading>Full</Section.Subheading>
              <figure className="full">
                <img src="https://picsum.photos/640/420" alt="Meeting" />
                <figcaption>
                  A random <a href="https://unsplash.com">Unsplash</a> meeting
                  image. Plus filler text to show a long caption in case you're
                  feeling especially wordy or just trying to stress test some
                  wysiwyg styles.
                </figcaption>
              </figure>
              <Highlight>{getExampleFullImageCaption()}</Highlight>
            </Section>
            <Section heading="Tables">
              <Section.Subheading>Normal</Section.Subheading>
              <div className="table-overflow">
                <table>
                  <thead>
                    <tr>
                      <th>First</th>
                      <th>Second</th>
                      <th>Third</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptate assumenda, voluptatum cumque laborum
                        nemo reprehenderit.
                      </td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Tempore dolorum quisquam recusandae, quam odit
                        consequuntur voluptatum.
                      </td>
                      <td>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Laudantium reiciendis similique animi ab totam
                        aspernatur inventore, necessitatibus maiores!
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Aliquam, quaerat. Dolor esse impedit earum
                        distinctio provident.
                      </td>
                      <td>
                        Reprehenderit, quas. Repellat odit provident a ipsam
                        debitis dicta ullam quod velit, alias autem ratione
                        magni perspiciatis?
                      </td>
                      <td>
                        Perferendis voluptatibus quae temporibus soluta magnam
                        nihil expedita placeat, quaerat dignissimos vel magni
                        ipsam libero repellendus.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Voluptatum, possimus? Consequatur quos aliquid culpa,
                        doloremque aperiam sapiente dolor esse eius rerum
                        inventore commodi atque mollitia quibusdam.
                      </td>
                      <td>
                        Delectus id sed, corrupti consequatur molestiae
                        obcaecati beatae culpa dignissimos aperiam esse porro
                        suscipit in nisi nihil fuga fugiat quo quibusdam dolore
                        cum enim vero.
                      </td>
                      <td>
                        <ul>
                          <li>Lorem ipsum dolor sit.</li>
                          <li>Doloremque numquam soluta tenetur!</li>
                          <li>Et nostrum soluta mollitia.</li>
                          <li>Suscipit ad eveniet hic?</li>
                          <li>Veniam maiores asperiores fuga?</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Highlight>{getExampleTableHTML()}</Highlight>
              <Section.Subheading>Wide</Section.Subheading>
              <figure className="wide">
                <div className="table-overflow">
                  <table>
                    <thead>
                      <tr>
                        <th>First</th>
                        <th>Second</th>
                        <th>Third</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Voluptate assumenda, voluptatum cumque laborum
                            nemo reprehenderit.
                          </p>
                        </td>
                        <td>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Tempore dolorum quisquam recusandae, quam odit
                            consequuntur voluptatum.
                          </p>
                        </td>
                        <td>
                          <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Laudantium reiciendis similique animi ab totam
                            aspernatur inventore, necessitatibus maiores!
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </figure>
              <Highlight>{getExampleTableWideHTML()}</Highlight>
            </Section>
            <Section heading="Callout">
              <Section.Subheading>Standard content-width</Section.Subheading>
              <figure className="callout bg-mint">
                <h3>Why creative service providers work the way they do</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
                  minus inventore iusto, error eum, enim magnam cupiditate quam
                  vitae at dolore nobis ab minima voluptas culpa, harum
                  consectetur! Voluptatem, blanditiis!
                </p>
                <blockquote>
                  <q>
                    After being in-house and at agencies of all sizes, I chose
                    to work as a freelancer to work more closely with clients
                    and to have more flexibility in the work I take on. I like
                    to attract companies in their early stages where I can
                    collaborate with co-founders to shape their brand or redo
                    their brand, making a big impact upfront and setting them up
                    for success so they can look professional for funding. At a
                    big agency, it was a lot less about shaping new brands and a
                    lot more about working within an existing brand and doing a
                    specific campaign or touchpoint.
                  </q>
                  <cite>
                    Jessica Strelioff, independent designer and creative
                    director
                  </cite>
                </blockquote>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
                  minus inventore iusto, error eum, enim magnam cupiditate quam
                  vitae at dolore nobis ab minima voluptas culpa, harum
                  consectetur! Voluptatem, blanditiis!
                </p>
                <blockquote className="medium">
                  <q>
                    Another indicator is unequal participation or lack of
                    productive discussion among meeting attendees.
                  </q>
                  <cite>Devin Jacoviello</cite>
                </blockquote>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
                  minus inventore iusto, error eum, enim magnam cupiditate quam
                  vitae at dolore nobis ab minima voluptas culpa, harum
                  consectetur! Voluptatem, blanditiis!
                </p>
                <ul>
                  {getListItems().map(l => (
                    <li key={l}>{l}</li>
                  ))}
                </ul>
              </figure>
              <Highlight>{getCalloutStandardExampleHTML()}</Highlight>
              <Section.Subheading>Wide content-width</Section.Subheading>
              <figure className="callout wide bg-cookie">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
                  minus inventore iusto, error eum, enim magnam cupiditate quam
                  vitae at dolore nobis ab minima voluptas culpa, harum
                  consectetur! Voluptatem, blanditiis!
                </p>
                <blockquote className="large">
                  <q>
                    Another indicator is unequal participation or lack of
                    productive discussion among meeting attendees.
                  </q>
                  <cite>Devin Jacoviello</cite>
                </blockquote>
              </figure>
              <Highlight>{getCalloutWideExampleHTML()}</Highlight>
            </Section>
            <Section heading="Video embed">
              <Section.Subheading>Standard content-width</Section.Subheading>
              <figure className="video-embed">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/DQccvofuojI?si=H2vJwUOgnwDRGJzi"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </figure>
              <Highlight>{getVideoEmbedStandardExampleHTML()}</Highlight>
              <Section.Subheading>Wide content-width</Section.Subheading>
              <figure className="video-embed wide">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/DQccvofuojI?si=H2vJwUOgnwDRGJzi"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </figure>
              <Highlight>{getVideoEmbedWideExampleHTML()}</Highlight>
              <Section.Subheading>Looping video (youtube)</Section.Subheading>
              <figure className="video-embed no-pointer">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/DQccvofuojI?si=H2vJwUOgnwDRGJzi?loop=1&controls=0&showinfo=0&rel=0&autoplay=1&mute=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </div>
              </figure>
              <Highlight>{getVideoEmbedLoopEmbedExampleHTML()}</Highlight>
              <Section.Subheading>
                Looping video (native, wide)
              </Section.Subheading>
              <figure className="video-embed wide">
                <div className="video-wrapper">
                  <video loop autoPlay muted playsInline>
                    <source src="/test-video.mp4" type="video/mp4" />
                  </video>
                </div>
              </figure>
              <Highlight>{getVideoEmbedLoopNativeExampleHTML()}</Highlight>
            </Section>
            <Section heading="Linked items">
              <Section.Subheading>Linked item</Section.Subheading>
              <figure className="linked-items">
                <a href="#" className="linked-item">
                  <img src="https://picsum.photos/720/360" alt="FPO" />
                  <div>
                    The Comprehensive Guide to Building Your Startup Design Team
                  </div>
                </a>
              </figure>
              <Highlight>{getLinkedItemExampleHTML()}</Highlight>
              <Section.Subheading>Linked items</Section.Subheading>
              <figure className="linked-items">
                <a href="#" className="linked-item">
                  <img src="https://picsum.photos/600/400" alt="FPO" />
                  <div>
                    The Comprehensive Guide to Building Your Startup Design Team
                  </div>
                </a>
                <a href="#" className="linked-item">
                  <img src="https://picsum.photos/600/400" alt="FPO" />
                  <div>
                    The ROI of Branding Best Practices for a High Impact Project
                  </div>
                </a>
              </figure>
              <Highlight>{getLinkedItemsExampleHTML()}</Highlight>
              <Section.Subheading>
                Linked items (wide and pad/bg)
              </Section.Subheading>
              <figure className="linked-items wide padded bg-n90">
                <a href="#" className="linked-item">
                  <img src="https://picsum.photos/540/280" alt="FPO" />
                  <div>
                    The Comprehensive Guide to Building Your Startup Design Team
                  </div>
                </a>
                <a href="#" className="linked-item">
                  <img src="https://picsum.photos/540/280" alt="FPO" />
                  <div>
                    The ROI of Branding Best Practices for a High Impact Project
                  </div>
                </a>
              </figure>
              <Highlight>{getLinkedItemsWideBgExampleHTML()}</Highlight>
            </Section>
          </div>
        </div>
      </>
    )
  }
}

function Section({ heading, children }: any) {
  return (
    <section className="wysiwyg">
      <h2>{heading}</h2>
      {children}
      <hr style={{ marginTop: 48, marginBottom: 48 }} />
    </section>
  )
}

function Subheading({ children }: any) {
  return <h3>{children}</h3>
}

Section.Subheading = Subheading

function getListItems() {
  return [
    'Name a facilitator to keep the meeting on track.',
    'Before the meeting, ask each presenter sign up for the amount of time they need for feedback.',
    'When presenting your work, always state which aspects of the work you’re seeking feedback on.',
    'Give feedback one by one around the room. Stop when time is up or when a round of feedback yields only 1’ or pass.’',
    'When receiving feedback on your work, just listen. Speak up only to respond to a clarifying question.',
  ]
}

/* Quotes */

function getExampleMarkdownQuote() {
  return `> Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas sed diam eget risus varius blandit sit amet non magna.`
}

function getExampleBasicQuote() {
  return `<blockquote>
  <q>
    Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas sed diam eget risus varius blandit sit amet non magna.
  </q>
  <cite>Dan Morgan</cite>
</blockquote>`
}

function getExampleMediumQuote() {
  return `<blockquote class="medium">
  <q>
    Another indicator is unequal participation or lack of productive discussion among meeting attendees.
  </q>
  <cite>Devin Jacoviello</cite>
</blockquote>`
}

function getExampleLargeQuote() {
  return `<blockquote class="large">
  <q>
    Another indicator is unequal participation or lack of productive discussion among meeting attendees.
  </q>
  <cite>Adam Ho</cite>
</blockquote>`
}

function getExampleQuoteWithImage() {
  return `<blockquote class="with-image">
  <img
    src="https://picsum.photos/420/420"
    alt="Person"
  />
  <div>
    <q>
      Another indicator is unequal participation or lack of
      productive discussion among meeting attendees.
    </q>
    <cite>Adam Ho</cite>
  </div>
</blockquote>`
}

function getExampleQuoteWithAvatar() {
  return `<blockquote className="with-avatar">
  <img src="https://picsum.photos/420/420" alt="Person" />
  <q>
    This is a quote with an image of the author. It's a small
    quote and it was written by Copilot.
  </q>
  <cite>Dan M</cite>
</blockquote>`
}

/* Images */

function getExampleMarkdownImage() {
  return `![Accessibility title](https://picsum.photos/420/420)`
}

function getExampleWideImage() {
  return `<figure class="wide">
  <img
    src="https://picsum.photos/420/420"
    alt="Office"
  />
</figure>`
}

function getExampleFullImage() {
  return `<figure class="full">
  <img
    src="https://picsum.photos/420/420"
    alt="Meeting"
  />
</figure>`
}

function getExampleWideImageCaption() {
  return `<figure class="wide">
  <img
    src="https://picsum.photos/420/420"
    alt="Office"
  />
  <figcaption>
    A random Unsplash <em>office</em> image
  </figcaption>
</figure>`
}

function getExampleFullImageCaption() {
  return `<figure class="full">
  <img
    src="https://picsum.photos/420/420"
    alt="Meeting"
  />
  <figcaption>
    A random <a href="https://unsplash.com">Unsplash</a> meeting
    image. Plus filler text to show a long caption in
    case you're feeling especially wordy or just trying to stress
    test some wysiwyg styles.
  </figcaption>
</figure>`
}

function getExampleImageWithQuote() {
  return `<figure class="full with-quote">
  <img
    src="https://picsum.photos/420/420"
    alt="Meeting"
  />
  <blockquote class="medium">
    <q>
      We believe in designed design. Some people do not like design.
    </q>
    <cite>Ben Blumenfeld</cite>
  </blockquote>
</figure>`
}

function getExampleImageWithQuoteAndSmallImage() {
  return `<figure class="wide with-quote with-small-image">
  <img
    src="https://picsum.photos/420/420"
    alt="Meeting"
  />
  <blockquote class="medium">
    <q>
      We believe in designed design. Some people do not like
      design.
    </q>
    <cite>Ben Blumenfeld</cite>
  </blockquote>
</figure>`
}

function getExampleTableHTML() {
  return `<div class="table-overflow">
  <table>
    <thead>
      <tr>
        <th>First</th>
        <th>Second</th>
        <th>Third</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Voluptate assumenda, voluptatum cumque laborum nemo
          reprehenderit.
        </td>
        <td>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Tempore dolorum quisquam recusandae, quam odit
          consequuntur voluptatum.
        </td>
        <td>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Laudantium reiciendis similique animi ab totam aspernatur
          inventore, necessitatibus maiores!
        </td>
      </tr>
      <tr>
        <td>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          Aliquam, quaerat. Dolor esse impedit earum distinctio
          provident.
        </td>
        <td>
          Reprehenderit, quas. Repellat odit provident a ipsam
          debitis dicta ullam quod velit, alias autem ratione magni
          perspiciatis?
        </td>
        <td>
          Perferendis voluptatibus quae temporibus soluta magnam
          nihil expedita placeat, quaerat dignissimos vel magni
          ipsam libero repellendus.
        </td>
      </tr>
    </tbody>
  </table>
</div>`
}

function getExampleTableWideHTML() {
  return `<figure class="wide">
    <div class="table-overflow">
    <table>
      <thead>
        <tr>
          <th>First</th>
          <th>Second</th>
          <th>Third</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptate assumenda, voluptatum cumque laborum nemo
            reprehenderit.
          </td>
          <td>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Tempore dolorum quisquam recusandae, quam odit
            consequuntur voluptatum.
          </td>
          <td>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Laudantium reiciendis similique animi ab totam aspernatur
            inventore, necessitatibus maiores!
          </td>
        </tr>
        <tr>
          <td>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Aliquam, quaerat. Dolor esse impedit earum distinctio
            provident.
          </td>
          <td>
            Reprehenderit, quas. Repellat odit provident a ipsam
            debitis dicta ullam quod velit, alias autem ratione magni
            perspiciatis?
          </td>
          <td>
            Perferendis voluptatibus quae temporibus soluta magnam
            nihil expedita placeat, quaerat dignissimos vel magni
            ipsam libero repellendus.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</figure>`
}

function getCalloutStandardExampleHTML() {
  return `<figure class="callout bg-mint">
  <h3>Why creative service providers work the way they do</h3>
  <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
    minus inventore iusto, error eum, enim magnam cupiditate quam
    vitae at dolore nobis ab minima voluptas culpa, harum
    consectetur! Voluptatem, blanditiis!
  </p>
  <blockquote>
    <q>
      After being in-house and at agencies of all sizes, I chose
      to work as a freelancer to work more closely with clients
      and to have more flexibility in the work I take on. I like
      to attract companies in their early stages where I can
      collaborate with co-founders to shape their brand or redo
      their brand, making a big impact upfront and setting them up
      for success so they can look professional for funding. At a
      big agency, it was a lot less about shaping new brands and a
      lot more about working within an existing brand and doing a
      specific campaign or touchpoint.
    </q>
    <cite>
      Jessica Strelioff, independent designer and creative
      director
    </cite>
  </blockquote>
  <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
    minus inventore iusto, error eum, enim magnam cupiditate quam
    vitae at dolore nobis ab minima voluptas culpa, harum
    consectetur! Voluptatem, blanditiis!
  </p>
  <blockquote class="medium">
    <q>
      Another indicator is unequal participation or lack of
      productive discussion among meeting attendees.
    </q>
    <cite>Devin Jacoviello</cite>
  </blockquote>
  <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
    minus inventore iusto, error eum, enim magnam cupiditate quam
    vitae at dolore nobis ab minima voluptas culpa, harum
    consectetur! Voluptatem, blanditiis!
  </p>
  <ul>
    <li>text 1</li>
    <li>text 2</li>
    <li>text 3</li>
  </ul>
</figure>`
}

function getCalloutWideExampleHTML() {
  return `<figure class="callout wide bg-cookie">
  <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
    minus inventore iusto, error eum, enim magnam cupiditate quam
    vitae at dolore nobis ab minima voluptas culpa, harum
    consectetur! Voluptatem, blanditiis!
  </p>
  <blockquote class="large">
    <q>
      Another indicator is unequal participation or lack of
      productive discussion among meeting attendees.
    </q>
    <cite>Devin Jacoviello</cite>
  </blockquote>
</figure>
`
}

function getVideoEmbedStandardExampleHTML() {
  return `<figure class="video-embed">
  <div class="video-wrapper">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/DQccvofuojI?si=H2vJwUOgnwDRGJzi"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  </div>
</figure>
`
}

function getVideoEmbedWideExampleHTML() {
  return `<figure class="video-embed wide">
  <div class="video-wrapper">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/DQccvofuojI?si=H2vJwUOgnwDRGJzi"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  </div>
</figure>
`
}

function getVideoEmbedLoopEmbedExampleHTML() {
  return `<figure class="video-embed no-pointer">
  <div class="video-wrapper">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/DQccvofuojI?si=H2vJwUOgnwDRGJzi?loop=1&controls=0&showinfo=0&rel=0&autoplay=1&mute=1"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>
  </div>
</figure>`
}

function getVideoEmbedLoopNativeExampleHTML() {
  return `<figure class="video-embed">
  <div class="video-wrapper">
    <video loop autoplay muted playsinline>
      <source src="//video.mp4" type="video/mp4" />
      <source src="//video.webm" type="video/webm" />
      <source src="//video.ogv" type="video/ogg" />
    </video>
  </div>
</figure>`
}

function getLinkedItemExampleHTML() {
  return `<figure class="linked-items">
  <a href="#" class="linked-item">
    <img src="https://picsum.photos/720/360" alt="FPO" />
    <div>
      The Comprehensive Guide to Building Your Startup Design Team
    </div>
  </a>
</figure>`
}

function getLinkedItemsExampleHTML() {
  return `<figure class="linked-items">
  <a href="#" class="linked-item">
    <img src="https://picsum.photos/600/400" alt="FPO" />
    <div>
      The Comprehensive Guide to Building Your Startup Design Team
    </div>
  </a>
  <a href="#" class="linked-item">
    <img src="https://picsum.photos/600/400" alt="FPO" />
    <div>
      The ROI of Branding Best Practices for a High Impact Project
    </div>
  </a>
</figure>`
}

function getLinkedItemsWideBgExampleHTML() {
  return `<figure class="linked-items wide padded bg-n90">
  <a href="#" class="linked-item">
    <img src="https://picsum.photos/540/280" alt="FPO" />
    <div>
      The Comprehensive Guide to Building Your Startup Design Team
    </div>
  </a>
  <a href="#" class="linked-item">
    <img src="https://picsum.photos/540/280" alt="FPO" />
    <div>
      The ROI of Branding Best Practices for a High Impact Project
    </div>
  </a>
</figure>`
}
